import React from "react"
import AboutUsUk from "../../components/body/pages/en-gb/about"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/about/"}
      title="About Kuda | The Money App for Africans"
      description="We came from frustration with excessive bank charges (for inconsistent services), now we’re here. We are the money app for Africans around the world."
    />
    <AboutUsUk />
  </Layout>
)

export default AboutPage
