import React from "react";
import Babs from "../../../../../../assets/img/babs_ceo.jpg"
import Musty from "../../../../../../assets/img/musty_cto.jpg";
import { Img } from "react-image";
import About2 from "../../../../../../assets/img/blur/about2.inline.svg";
import About3 from "../../../../../../assets/img/blur/about3.inline.svg";

const OurStory = () => {


    return (
        <div className="kuda-section kuda-standard--section kuda-story--section">
            <div className="kuda-section--inner">
                <div className="kuda-section--100">
                    <h1 className="kuda-section--heading text-center text-xlbold color-primary title-bottom--spacing">
                        We’re here because of you.
                    </h1>
                    <div className="text-center justify-center flex flex-column align-center kuda-story--wrap">
                        <p className="color-black kuda-section--max bottom--spacing">
                            We’re a fintech company on a mission to make financial services more accessible, affordable and rewarding for every African on the planet
                        </p>
                        <p className="color-black kuda-section--max bottom--spacing">
                            Kuda was founded in Nigeria by Babs Ogundeyi and Musty Mustapha in 2019, and we have offices in London, Cape Town, and Lagos.
                        </p>
                        <p className="color-black kuda-section--max bottom--spacing">
                            We launched our app, also called Kuda, in August 2019, giving Africans with internet-enabled smartphones a way to operate a full-fledged bank account, save and earn interest, access instant credit, and send money without exorbitant fees.
                        </p>
                        {/* <p className="color-black kuda-section--max bottom--spacing">
                            So no matter who you are or where you live in Nigeria, we’re here because of you. We know the pain that comes with using a regular bank and we will make things work better for everyone.
                        </p>
                        <p className="color-black text-bold kuda-section--max">
                            Let’s do this together.
                        </p> */}
                    </div>
                </div>
                <div className="kuda-team flex justify-center">
                    <div className="kuda-profile--wrap">

                        <Img alt="kuda Microfinance Bank CEO Babs Ogundeyi"
                            src={Babs}
                            loader={<About2 className="blur kuda-team--member" />} className="kuda-team--member"
                        />
                        <div className="kuda-profile--item">
                            <p className="color-primary text-xlbold">Babs Ogundeyi</p>
                            <p className="color-black">Co-Founder & CEO</p>
                        </div>
                    </div>
                    <div className="kuda-profile--wrap">
                        <Img alt="kuda Microfinance Bank CTO Musty Mustapha"
                            src={Musty}
                            loader={<About3 className="blur kuda-team--member" />} className="kuda-team--member"
                        />
                        <div className="kuda-profile--item">
                            <p className="color-primary text-xlbold">Musty Mustapha</p>
                            <p className="color-black">Co-Founder & CTO</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurStory;

