import React, { useEffect, Fragment } from "react";
// import Illustration from "../../../../../assets/img/uk-about.png";
import About1 from "../../../../../assets/img/blur/kuda-new-about.inline.svg";
import Testimonials from "../../home/sections/testimonials";
import { scrollToElement } from "../../../../utility/utils";
import EntryFold from "../../general/entryFold";
import OurStory from "./sections/ourStory";
import CTAUk from "../../general/ctaUk";
import { StaticImage } from "gatsby-plugin-image";

const entryContent = {
    title: "About Kuda",
    subtitle: "We came from frustration with excessive bank charges (for inconsistent services), now we’re here.",
    illustration: <StaticImage src={`../../../../../assets/img/uk-about.png`} placeholder="blurred" alt="kuda Microfinance Bank Savings, Spend, Budgeting." loading="eager"/>

}

const AboutUsUk = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <EntryFold ctaClass={"desktop-only"} title={entryContent.title} subtitle={entryContent.subtitle} illustration={entryContent.illustration} isImage={true} fallback={<About1 className="blur isImage" />} />
            <OurStory />
            <Testimonials />
            <CTAUk />
        </Fragment>
    )
}

export default AboutUsUk;
